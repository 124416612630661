<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
import axios from '@/axios'
// import CreateVideo from '@components/video/createvideo'
import CreateSub from '@components/subcategory/sub/createsub'

// import PageHeader from '@components/page-header'

/**
 * Contents component
 */
export default {
  page: {
    title: 'Create',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    Layout,
    // CreateVideo,
    CreateSub,

    // PageHeader
  },
  data() {
    return {
      data: {},
      is_sub: null,
    }
  },
  mounted() {
    this.onLode()
  },
  methods: {
    async onLode() {
      await axios
        .get(`/api/category/${this.$route.params.id}`)
        .then((res) => {
          this.data = res.data
        })
        .catch((err) => {
          alert(err.response.data.message)
        })
    },
  },
}
</script>

<template>
  <Layout>
    <div class="row page-title align-items-center">
      <div class="col-md-3 col-xl-6">
        <h4 class="mb-1 mt-0"></h4>
      </div>

      <div class="row mt-2" style="height:100%; width:100%;">
        <div class="col-12">
          <div class="card">
            <CreateSub></CreateSub>
            <!-- <CreateVideo :sub_id="this.$route.params.id"></CreateVideo> -->
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
